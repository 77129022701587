import React from 'react'
import Header from './Header'
import HomeBody from './HomeBody'
import Footer from './Footer.js'

export default function 
() {
  return (<>
  <Header />
    
  <HomeBody />
 
<Footer />
        
  </>
  )
}
